$icon-font-path: '../fonts/';

// fonts
@import url(//fonts.googleapis.com/css?family=Roboto:300,400,500,600,700);

// colors
$green: #3EB049;
$red: #DD272D;
$footer-color: rgba(95, 98, 107, 1);

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */
body {
  font-family: 'Roboto';
  padding-bottom: 60px;
}
a:link {
 color: $green;
}
a:visited {
 color: $green;
}
a:hover {
 color: $green;
}
a:active {
 color: $green;
}

a.anchor {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
}

h1, h2, h3, h4, p {
  &:target {
    background-color: $green;
    border-radius: 3px;
    -webkit-transition: all 250ms linear;
    padding: 0.5em 0.5em 0.5em 0.5em;
  }
}

h4 {
  padding: auto 0.5em;
}

h2.ftp {
  color: #4A4A4A;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
  clear: both;
}

img.deco {
  margin-bottom: 15px;
  margin-top: 12px;
  box-shadow: 0px 0px 10px $footer-color;
}

.preform {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  background-color: #eff5ea;
  font-family: monospace;
  white-space: pre-line;
}

small {
  color: $red;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
}

form div {
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;
}

form hr {
  clear: both;
  border-top: 1px solid #d3d3d3;
  margin-right: 5px;
}

label {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-height: 14px;
  color: #4A4A4A;
}

::placeholder {
    color: #9B9B9B;
}

:-ms-input-placeholder {
    color: #9B9B9B;
}

::-ms-input-placeholder {
    color: #9B9B9B;
}

input:focus {
  outline: none;
}

input[type="text"], input[type="password"] {
  display: block;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  color: #4A4A4A;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 8px;
  width: 263px;
}

input[type="text"]:focus, input[type="password"]:focus {
  border: 1px solid $green;
}

.error {
  border: 1px solid $red !important;
}

.radio-error:before {
    border: 1px solid $red !important;
}

div#port {
  margin-right: 47px;
}

input#port {
  width: 69px; /* nice */
}

#ftp-submit-container {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}

#ftp-response-field {
  float: left;
  padding-top: 5px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin-top: 24px;
  margin-right: 27px;
}

.radio-item input[type='radio'] {
  display: none;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid #D3D3D3;
  background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: $green;
}

.required-star {
  color: red;
}

button {
  background-color: $green;
  color: white;
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 500;
  height: 32px;
  border-radius: 4px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 0px;
}

button#ftp-submit {
  width: 258px;
  // margin-top: 24px;
  margin-right: 5px;
}

/* Custom page header */
.header {
  z-index: 999;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  border-bottom: 1px solid $green;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
}

section {
  padding-top: 35px;
  padding-bottom: 80px;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/springbot/install/background.jpg");
}

.page-title {
  font-family: 'Roboto';
  font-weight: 200;
  font-style: normal;
  color: #FFFFFF;
  margin-bottom: 40px;
  text-align: center;
  text-shadow: 0px 1px 10px rgba(0, 0, 0, 0.85);
}

.wrapper {
  position: relative;
  min-height: 100%;
}

.content {
  background-color: white;
  padding-top: 72px;
  min-height: 740px;
}

/* Custom page footer */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  color: white;
  padding-top: 10px;
  background-color: $footer-color;
  border-top: 1px solid $green;
  text-align: center;
}

/* Magento2 modules */
#mage2-update {
  padding-top: 40px;
}
#mage2-install {
  padding-top: 40px;
}
#mage2-uninstall {
  padding-top: 40px;
}
/* Magento1 modules */
#mage1-update {
  padding-top: 40px;
}
#mage1-install {
  padding-top: 40px;
}
#mage1-uninstall {
  padding-top: 40px;
}

.footer a:link { color: $green; }
.footer a:visited { color: $green; }
.footer a:hover { color: $green; }
.footer a:active { color: $green; }
.integration-box a:link {
 color: white;
}
.integration-box a:visited {
 color: white;
}
.integration-box a:hover {
 color: white;
}
.integration-box a:active {
 color: white;
}
.integration-box {
  display: inline-block;
  margin: 10px;
  width: 360px;
  height: 215px;
  background-color: white;
  border-radius: 6px;
  padding: 20px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, .15);
}

.integration-icon {
  height: 96px;
}

.woo-icon {
  height: 75px;
}

.woo-padding {
  padding-top: 25px;
}

.integration-help-text {
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.integration-btn {
  width: 165px;
  max-width: 100%;
  font-family: 'Roboto';
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
}

a.install-link, a:hover.install-link, a:visited.install-link, a:active.install-link {
  color: white;
  text-decoration: underline;
}

.grey-out-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: gray;
  top: 0;
  left: 0;
  padding: 10px;
  opacity: .8;
  z-index: 1000;
}

.shopify-modal {
  width: 400px;
  height: 130px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -200px; /* half width*/
  margin-top: -65px; /* half height*/
  z-index: 1001;
  border: 2px solid #4cae4c;

  p {
    width: 100%;
    text-align: center;
    padding-top: 10px;
  }

  button {
    left: 50%;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 10px;
  }

  input {
    left: 50%;
    width: 250px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 10px;
  }

}
